import { useEffect, useState } from 'react';
import { Progress as ChakraProgress } from '@chakra-ui/react';

export const Progress: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return <ChakraProgress size='xs' hasStripe value={progress} />;
};
