import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './themes';
import { FirebaseProvider } from './firebase';
import { AppRouter } from './AppRouter';

const App: React.FC = () => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <FirebaseProvider>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppRouter />
          </QueryClientProvider>
        </ChakraProvider>
      </HelmetProvider>
    </FirebaseProvider>
  );
};

export default App;
