import { createContext, useContext, useEffect, useState } from 'react';
import { AppCheck, getToken, onTokenChanged } from 'firebase/app-check';

import { ClientOptions } from '../clients';
import { initAppCheck } from './app-check';
import { initFirebase } from './app';

type FirebaseContextProps = {
  appToken: string;
  options: ClientOptions;
};

const options: ClientOptions = {
  getAppToken: () => new Promise(() => ''),
  retries: undefined,
};

const FirebaseContext = createContext<FirebaseContextProps>({
  appToken: '',
  options,
});

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider: React.FC = ({ children }) => {
  const [appCheck, setAppCheck] = useState<AppCheck | null>(null);
  const [appToken, setAppToken] = useState('');

  useEffect(() => {
    initFirebase();
    const appCheck = initAppCheck();
    setAppCheck(appCheck);
    const unsubscribe = onTokenChanged(appCheck, ({ token }) => {
      setAppToken(token);
    });
    return () => unsubscribe();
  }, []);

  const getAppToken = async () => {
    if (!appCheck) return '';
    try {
      const { token } = await getToken(appCheck);
      return token;
    } catch (error) {
      throw error;
    }
  };

  const options: ClientOptions = { getAppToken };
  const value: FirebaseContextProps = { appToken, options };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
