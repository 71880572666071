import { getApps, initializeApp } from 'firebase/app';

export const initFirebase = () => {
  const apps = getApps();
  if (apps.length) return;

  initializeApp({
    apiKey: 'AIzaSyDprzQcFwuKeZoJ01aJl4hBJMTCxGFHZeE',
    authDomain: 'listingops.firebaseapp.com',
    projectId: 'listingops',
    storageBucket: 'listingops.appspot.com',
    messagingSenderId: '43663801893',
    appId: '1:43663801893:web:7bd78ef870509290bd89cc',
  });
};
