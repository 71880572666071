import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Progress } from './components/progress';

const Apply = lazy(() => import('./pages/Apply'));
const Error = lazy(() => import('./pages/Error'));
const Lander = lazy(() => import('./pages/Lander'));
const Layout = lazy(() => import('./layout'));
const Request = lazy(() => import('./pages/Request'));
const Roi = lazy(() => import('./pages/Roi'));
const WalkThrough = lazy(() => import('./pages/WalkThrough'));

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<Progress />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Lander />} />
          <Route path='/roi' element={<Roi />} />
          <Route path='*' element={<Error />} />
        </Route>
        <Route path='/apply' element={<Apply />} />
        <Route path='/request' element={<Request />} />
        <Route path='/walk-through' element={<WalkThrough />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
